.pricing-banner {
    position: relative;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;

    .pricing-banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #07071c;
    }

    .s-container {
        text-align: center;
        flex-wrap: wrap;
        position: relative;

        h1 {
            font-size: 55px;
            letter-spacing: -0.04em;
            font-weight: normal;
            color: #fff;

            span {
                color: #e7a1ee;
            }
        }
    }

    .switch-plan {
        margin-top: 21px;
    }

    @media (min-width: 1200px) {
        .pricing-banner-bg {
            transform: translate(0%, -100%);
            overflow: hidden;
        }

        .s-container h1,
        .switch-plan {
            transform: translate(0px, 30px);
            opacity: 0;
        }
    }

    @media (max-width: 991px) {
        .s-container h1 {
            font-size: 44px;
        }
    }

    @media (max-width: 767px) {
        height: 500px;

        .s-container h1 {
            font-size: 32px;
            max-width: 260px;
        }
    }
}

.pricing-content {
    margin-top: -160px;
    padding-bottom: 120px;
    position: relative;

    .content-plan-container {
        position: sticky;
        top: 0;
        z-index: 1;

        .content-plan {
            border-bottom: 1px solid rgb(241 241 241 / 0%);
            transition: 0.4s;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
                bottom: 0;
                width: 0%;
                background-color: #fff;
                transition: 0.4s;
            }

            &.active {
                border-bottom-color: rgb(241 241 241 / 100%);

                &:before {
                    width: 100%;
                }

                .s-container {
                    border-color: #fff0;
                }

                .s-container > span,
                .plan-row .plan-title > span {
                    opacity: 0;
                }

                .plan-item {
                    .item-header p {
                        margin-bottom: -60px;
                    }

                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        .item-header p {
                            opacity: 0;
                            // height: 0;
                        }
                    }

                    .price {
                        > span:nth-child(1) {
                            opacity: 0;
                            // height: 0;
                        }

                        margin-top: -10px;
                    }

                    a {
                        margin-top: 20px;
                    }
                }
            }

            .s-container {
                background: #fff;
                border: 1px solid #f1f1f1;
                border-radius: 10px;
                padding: 0 0;
                transition: 0.4s;
                position: relative;

                > span {
                    display: block;
                    padding-left: 18px;
                    margin-top: 20px;
                    margin-bottom: -36px;
                    transition: 0.4s;
                }
            }

            .plan-row {
                display: flex;
                margin-top: -17px;
                margin-bottom: -1px;
                padding: 0px 18px;

                .plan-title {
                    flex: 0 0 240px;
                    display: flex;
                    align-items: center;

                    > span {
                        font-size: 15px;
                        color: #171010;
                        display: block;
                        transition: 0.4s;
                    }
                }
            }

            .plan-item {
                flex: 0 0 calc(15.9% - 27px);
                padding: 40px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                h2 {
                    font-size: 20px;
                    color: #000;
                    font-weight: normal;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 15px;
                    letter-spacing: -0.04em;
                    line-height: 22px;
                    color: #131211;
                    transition: 0.4s;
                }

                .price {
                    margin-top: 42px;
                    transition: 0.4s;

                    > span {
                        transition: 0.4s;

                        &:nth-child(1) {
                            font-size: 15px;
                            letter-spacing: -0.04em;
                            color: #131211;
                            display: block;
                        }

                        &:nth-child(2) {
                            font-size: 35px;
                            letter-spacing: -0.04em;
                            color: #131211;

                            > span:nth-child(1) {
                                font-size: 15px;
                            }
                        }
                    }
                }

                > span {
                    margin-top: 10px;
                    display: block;
                    font-size: 15px;
                    color: #131211;
                    transition: 0.4s;
                }

                a {
                    font-weight: bold;
                    font-size: 15px;
                    color: #fff;
                    display: block;
                    background: #0063f7;
                    border-radius: 10px;
                    padding: 16px;
                    text-align: center;
                    margin-top: 25px;
                    transition: 0.4s;

                    @media (hover: hover) {
                        &:hover {
                            background: #3d87ff;
                        }
                    }
                }
            }

            .plan-item + .plan-item {
                border-left: 2px solid #f1f1f1;
                margin-left: 18px;
                padding-left: 18px;
            }
        }
    }

    .content-body {
        margin-top: 90px;

        .body-item {
            + .body-item {
                margin-top: 80px;
            }

            .item-title {
                font-size: 25px;
                letter-spacing: -0.02em;
                color: #131211;
                display: block;
                margin-bottom: 30px;
            }

            .item-list .list-item {
                .list-title {
                    display: block;
                    font-size: 15px;
                    letter-spacing: 0.03em;
                    color: #0f0808;
                }

                + .list-item {
                    margin-top: -16px;

                    .list-row .row-item {
                        border-top: none;
                        border-bottom: none;
                    }
                }

                .list-row {
                    display: flex;
                    flex-wrap: wrap;

                    .row-item {
                        flex: 0 0 calc(16% - 34px);
                        max-width: calc(16% - 34px);
                        border-top: 1px dashed #f1f1f1;
                        border-bottom: 1px dashed #f1f1f1;
                        padding: 50px 0 60px;

                        + .row-item {
                            border-right: 2px solid #f1f1f1;
                            margin-right: 18px;
                            padding-right: 18px;
                            margin-left: -18px;
                            padding-left: 18px;
                        }

                        &:first-child {
                            flex: 0 0 258px;
                            max-width: 258px;
                            border-top: none;
                            border-bottom: none;
                        }

                        &:last-child {
                            border-right: none;
                            margin-right: 0;
                            padding-right: 0;
                        }

                        span {
                            font-size: 15px;
                            letter-spacing: 0.03em;
                            color: #625f5f;
                            display: block;
                            min-height: 42px;

                            + span {
                                margin-top: 44px;
                                line-height: 1.4;
                            }
                        }
                    }
                }
            }
        }
    }

    .content-info {
        max-width: 580px;
        margin: 100px auto 0;
        text-align: center;

        p {
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #625f5f;
        }
    }

    @media (min-width: 1200px) {
        .content-plan-container {
            transform: translate(0px, 50px);
            opacity: 0;
        }

        .content-body .body-item {
            .item-title {
                transform: translate(0px, 20px);
                opacity: 0;
            }

            .item-list .list-item .list-row .row-item {
                transform: translate(0px, 50px);
                opacity: 0;
            }
        }

        .content-info {
            transform: translate(0px, 30px);
            opacity: 0;
        }
    }

    @media (max-width: 1550px) {
        .content-plan-container .content-plan {
            .plan-item {
                padding: 30px 0;

                h2 {
                    font-size: 18px;
                }

                .price {
                    margin-top: 20px;
                }

                a {
                    font-size: 13px;
                    margin-top: 14px;
                }

                .price > span {
                    &:nth-child(2) {
                        > span {
                            &:nth-child(2),
                            &:nth-child(3) {
                                margin-bottom: -6px;
                            }
                        }

                        font-size: 30px;
                        display: flex;
                        align-items: flex-end;
                        margin-bottom: 4px;
                    }

                    &:nth-child(1) {
                        margin-bottom: 8px;
                    }
                }
            }

            &.active .plan-item {
                .price {
                    margin-top: -30px;
                }

                a {
                    margin-top: 10px;
                }
            }
        }

        .content-body .body-item {
            .item-list .list-item .list-row {
                justify-content: center;
            }

            .item-title {
                margin: 0 8px 30px;
            }
        }
    }

    @media (max-width: 1350px) {
        .s-container {
            max-width: 1200px;
        }
    }

    @media (max-width: 1199px) {
        .content-plan-container .content-plan {
            .plan-row {
                margin-top: 0;

                .plan-title {
                    display: none;
                }
            }

            .plan-item {
                flex: 0 0 calc(19.7% - 27px);
            }

            .s-container {
                max-width: 990px;

                > span {
                    display: none;
                }
            }
        }

        .content-body .body-item {
            .item-title {
                margin-left: 0;
            }

            .item-list .list-item .list-row {
                justify-content: initial;

                .row-item:first-child {
                    flex: 0 0 180px;
                    max-width: 180px;
                    padding-right: 20px;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .content-plan-container {
            position: relative;
        }

        .content-body .body-item .item-list .list-item .list-row {
            overflow-x: auto;
            overflow-y: hidden;
            flex-wrap: nowrap;
            margin: 0 -32px;
            padding-left: 32px;

            &::-webkit-scrollbar {
                height: 0;
            }

            .row-item {
                flex: 0 0 180px;
                max-width: 180px;

                &:first-child {
                    flex: 0 0 290px;
                    max-width: 0 0 290px;
                }
            }
        }

        .content-plan {
            .plan-item {
                flex: 0 0 calc(25% - 29px);
                padding-top: 70px;
            }
        }
    }

    @media (max-width: 767px) {
        .content-plan {
            .s-container {
                max-width: 100%;
                margin: 0 10px;

                > span {
                    display: none;
                }

                .plan-row {
                    flex-wrap: wrap;
                    margin-top: 0;
                }

                .plan-item {
                    flex: 0 0 calc(50% - 19px);
                    padding: 30px 0;

                    &:nth-child(4) {
                        margin-left: -18px;
                        margin-right: -18px;
                        padding-left: 18px;
                        padding-right: 18px;
                        border-left: none;
                        border-top: 2px solid #f1f1f1;
                    }

                    &:nth-child(5) {
                        border-top: 2px solid #f1f1f1;
                        margin-right: -20px;
                        flex: 0 0 calc(50% - 18px);
                        padding-right: 18px;
                    }

                    &:last-child {
                        padding: 30px 18px;
                        margin: 0 -18px;
                        border-top: 2px solid #f1f1f1;
                        border-left: none;
                        flex: 0 0100%;
                    }

                    a {
                        font-size: 13px;
                    }
                }
            }
        }

        .content-body .body-item .item-list .list-item .list-row {
            margin: 0 -24px;
            padding-left: 24px;

            .row-item {
                padding: 30px 0;

                span + span {
                    margin-top: 30px;
                }
            }
        }
    }
}
