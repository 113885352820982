@keyframes heroAstronaut {
    0%,
    100% {
        transform: translate(0px, -5px);
    }

    50% {
        transform: translate(0px, 5px);
    }
}

@media only screen and (min-width: 1200px) {
    .home-hero .home-hero-content .content-title .title-row .title > span.support-img > .img-item .img > div > img {
        animation: heroAstronaut 8s infinite both ease-in-out;
    }
}

@keyframes scrollDown {
    0% {
        transform: translate(0, -6px);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translate(0, 6px);
        opacity: 0;
    }
}

.world-info-list {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;

    .list-content {
        margin-left: 170px;
        margin-top: 100vh;

        .content-item {
            max-width: 29vw;
            height: 100vh;

            > span {
                font-weight: 700;
                font-size: 0.75vw;
                letter-spacing: 3px;
                text-transform: uppercase;
                color: #0642ff;
            }

            .item-title {
                font-weight: 700;
                font-size: 2.5vw;
                color: #ffffff;
                margin-top: 0.85vw;
            }

            p {
                font-size: 1.26vw;
                line-height: 1.9vw;
                color: #d7d8df;
                margin-top: 0.85vw;
            }
        }
    }

    .list-img {
        flex: 1;

        .list-img-sticky {
            position: sticky;
            top: 0;
            overflow: hidden;
            height: 100vh;

            img {
                position: absolute;
                top: 50%;
                opacity: 0;
                transform: translate(200px, -43%);
            }
        }
    }
}

// .world-info-container {
//     position: fixed;
//     top: 50%;
//     left: 0;
//     transform: translate(-50%, -50%);
//     transform-origin: left;
//     width: 700px;
//     z-index: 3;
//     padding-left: 60px;
//     opacity: 0;
//     pointer-events: none;
//     font-weight: 300;

//     .title {
//         font-size: 85px;
//         color: #ffffff;
//         display: block;
//     }

//     .desc-item {
//         position: relative;
//         margin-top: 45px;

//         p {
//             font-size: 35px;
//             line-height: 1.2;
//             color: #ffffff;
//             transition: 0.4s cubic-bezier(0.74, 0.04, 0.23, 0.93);

//             + p {
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 opacity: 0;

//                 &.active {
//                     opacity: 1;
//                 }
//             }

//             &.in-active {
//                 opacity: 0;
//             }
//         }
//     }

//     .line {
//         display: block;
//         width: 100%;
//         height: 1px;
//         background: #fff;
//         margin: 60px 0;
//     }

//     .country-row {
//         display: flex;
//         flex-wrap: wrap;
//         margin: 0 -8px;

//         .country-item {
//             border: 1px solid #fff;
//             text-align: center;
//             margin: 0 8px;
//             flex: 0 0 calc(30% - 16px);
//             padding: 40px 0 50px;
//             position: relative;
//             transition: 0.4s cubic-bezier(0.74, 0.04, 0.23, 0.93);

//             &.in-active {
//                 opacity: 0.3;
//             }

//             &.active {
//                 img {
//                     filter: grayscale(0);
//                 }

//                 .item-text > span:nth-child(2) {
//                     transform: translate(0px, 20px);
//                     opacity: 0;
//                 }

//                 .item-text > span:nth-child(1) {
//                     transform: translate(0px, -20px);
//                 }

//                 .item-number {
//                     transform: translate(-50%, 0px);
//                     opacity: 1;
//                 }
//             }

//             img {
//                 filter: grayscale(1);
//                 transition: 0.4s cubic-bezier(0.74, 0.04, 0.23, 0.93);
//             }

//             .item-number {
//                 position: absolute;
//                 left: 50%;
//                 transform: translate(-50%, 20px);
//                 width: 100%;
//                 bottom: 30px;
//                 opacity: 0;
//                 transition: 0.4s cubic-bezier(0.74, 0.04, 0.23, 0.93);

//                 > span {
//                     &:nth-child(1) {
//                         font-size: 15px;
//                         color: white;
//                         display: block;
//                     }

//                     &:nth-child(2) {
//                         color: white;
//                         font-size: 30px;
//                         margin-top: 10px;
//                         display: block;
//                     }
//                 }
//             }

//             .item-text > span {
//                 transition: 0.4s cubic-bezier(0.74, 0.04, 0.23, 0.93);

//                 &:nth-child(1) {
//                     font-size: 20px;
//                     color: #ffffff;
//                     display: block;
//                 }

//                 &:nth-child(2) {
//                     font-size: 16px;
//                     color: #ffffff;
//                     display: block;
//                     margin-top: 20px;
//                 }
//             }
//         }
//     }

//     @media (max-height: 900px) {
//         transform: translate(-50%, -50%) scale(0.8);
//     }

//     @media (max-height: 740px) {
//         transform: translate(-50%, -50%) scale(0.7);
//     }

//     @media (max-height: 620px) {
//         transform: translate(-50%, -50%) scale(0.6);
//     }

//     @media (max-width: 1199px) {
//         display: none;
//     }
// }

.home-hero {
    background: #000;
    position: relative;
    font-weight: 300;

    &-container {
        // position: fixed;
        // top: 0;
        // left: 0;
        // right: 0;
        // height: 100vh;

        &.pointer-none {
            pointer-events: none;
        }

        @media only screen and (min-width: 1200px) {
            margin-bottom:10%;

        }
    }

    .s-container {
        height: 100vh;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
    }

    .marquee-container {
        overflow: hidden;
        padding-top: 400px;
        padding-bottom: 50px;

        .marquee-item {
            background: #0642ff;
            box-shadow: 0px 100px 426px rgba(0, 0, 0, 0.23), 0px 42.945px 155.497px rgba(0, 0, 0, 0.0975134),
                0px 22.6663px 75.4909px rgba(0, 0, 0, 0.0332666);
            border: none;
            padding: 43px 0px;

            img {
                max-width: 300px;
            }

            &:first-child {
                background: #023dd3;
            }

            &:nth-child(2) {
                margin-top: -130px;
                margin-right: -10px;
                margin-left: -10px;
            }
        }

        @media only screen and (max-width: 1199px) {
            padding-top: 250px;
        }

        @media only screen and (max-width: 767px) {
            padding-top: 150px;
        }
    }

    .home-hero-content {
        position: relative;
        z-index: 1;
        padding: 0 90px;

        .content-title {
            padding-top: 350px;
        }

        .content-desc {
            padding-top: 350px;

            .text {
                font-size: 94px;
                color: #ffffff;
                font-weight: 300;
                max-width: 1300px;
                line-height: 1.1;

                > .text-item-bg {
                    position: relative;

                    > .bladiv#earth-container {
                        position: relative;
                        z-index: 1;
                    }

                    > .text-bg {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 41px;
                        background: #0642ff;
                    }
                }
            }
        }

        .content-title {
            > p {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.6);
                margin-top: 32px;
            }

            .title-row {
                display: flex;
                position: relative;

                &:nth-child(1) .title > span {
                    position: relative;

                    > svg {
                        position: absolute;
                        top: -13px;
                        left: -10vw;
                        width: 35vw;
                        height: 7.6vw;
                    }
                }

                .line {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: #fff;
                }

                &:nth-child(1) .line:nth-child(2),
                &:nth-child(2) > .line {
                    bottom: 0;
                    top: auto;
                }

                .title {
                    font-size: 6.8vw;
                    color: #ffffff;
                    flex: 1;
                    position: relative;
                    display: flex;
                    align-items: center;
                    font-weight: 300;

                    > span + span {
                        margin-left: 2vw;
                    }
                }

                .svg-star {
                    position: relative;
                    padding: 20px 50px;
                    display: flex;
                    align-items: center;

                    > span {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 1px;
                        background: #fff;
                    }
                }

                .title > {
                    .support-img {
                        position: absolute;
                        bottom: 1px;
                        transform-origin: bottom right;
                        transform: scale(1);
                        right: 40px;
                        z-index: 1;
                        height: 200px;

                        > .img-item {
                            position: relative;
                            transform: translate(200px, -100px);
                            line-height: 0;
                        }

                        > span {
                            font-size: 22px;
                            position: absolute;
                            right: -200px;
                            top: 64px;
                        }
                    }
                }

                .supported {
                    display: flex;
                    align-items: center;
                    padding: 70px 0 30px 50px;
                    position: relative;
                }

                .title > span.support-img > .img-item svg {
                    position: absolute;
                    right: -215px;
                    top: 40px;
                }

                .svg-star svg {
                    margin-bottom: -2px;
                }

                .supported {
                    .line {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 1px;
                        height: 100%;
                    }

                    > span:nth-child(3) {
                        display: block;
                        width: 45px;
                        height: 1px;
                        background: #fff;
                        margin: 0 35px -20px 30px;
                    }
                }
            }
        }
    }

    .home-hero-marquee {
        position: absolute;
        top: 1900px;
        left: 0;
        width: 100vw;
        padding: 50px 0;
        height: 65px;
        display: flex;
        align-items: center;
        z-index: 1;
        overflow: hidden;

        .home-hero-marquee-line {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: #fff;

            &:nth-child(2) {
                bottom: 0;
                top: auto;
            }
        }

        .home-hero-marquee-row {
            display: flex;
            flex-wrap: nowrap;
            white-space: nowrap;
            position: relative;

            .home-hero-marquee-item {
                > span {
                    font-size: 54px;
                    color: #ffffff;
                    display: block;
                    white-space: nowrap;
                    line-height: 1.2;
                    text-align: right;

                    > span:nth-child(1) {
                        display: inline-block;
                        margin: 0 40px 0 60px;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 992px) {
        .home-hero-content .content-title .title-row .title {
            font-size: 5.6vw;
        }
    }

    @media only screen and (min-width: 1200px) {
        .home-hero-content {
            .content-title {
                .title-row {
                    .title-text {
                        overflow: hidden;
                        display: block;
                        padding-bottom: 20px;
                        margin-bottom: -40px;

                        > span {
                            transform: translate(0px, 120%);
                            display: block;
                        }
                    }

                    &:nth-child(2) > .title {
                        margin-top: -7px;
                    }

                    > .line {
                        width: 0;
                    }

                    &:nth-child(1) .title > span > svg {
                        stroke-dasharray: 780;
                        stroke-dashoffset: 780;
                    }

                    .svg-star {
                        > span {
                            height: 0%;
                        }

                        svg {
                            transform: scale(0);
                        }
                    }

                    .supported {
                        .line {
                            height: 0%;
                        }

                        .support-item {
                            transform: translate(-10px, 0px);
                            opacity: 0;

                            &:nth-child(4) {
                                margin-left: 100px;
                            }
                        }

                        > span:nth-child(3) {
                            position: absolute;
                            bottom: 43px;
                            left: 190px;
                            transform: scale(0, 1);
                            margin: 0;
                        }
                    }
                }

                > p {
                    transform: translate(0px, 30px);
                    opacity: 0;
                }
            }

            .content-desc .text {
                transform: translate(0px, 400px);

                > span {
                    overflow: hidden;
                    display: inline-block;
                    padding-bottom: 10px;
                    margin-bottom: -20px;

                    &:not(.text-item-bg) > span {
                        display: inline-block;
                        transform: translate(0px, 110%);
                    }
                }

                > .text-item-bg > {
                    .blast {
                        transform: translate(0, 100%);
                        display: inline-block;
                    }

                    .text-bg {
                        width: 0;
                    }
                }
            }

            .content-title .title-row .title > {
                span.support-img > .img-item {
                    .img {
                        overflow: hidden;

                        > div {
                            transform: translate(0px, 100%);
                        }
                    }

                    svg {
                        transform: scale(1, 1);
                        transform-origin: left;
                        opacity: 0;
                    }
                }

                .support-img > span {
                    opacity: 0;
                }
            }
        }

        .home-hero-marquee {
            .home-hero-marquee-line {
                right: 0;
                left: auto;
                width: 0%;
            }
        }
    }

    @media only screen and (min-width: 1350px) {
        .home-hero-content .content-title .title-row .title {
            font-size: 6.1vw;
        }
    }

    @media only screen and (min-width: 1500px) {
        .home-hero-content .content-title .title-row .title {
            font-size: 6.2vw;
        }
    }

    @media only screen and (max-width: 1470px) {
        .home-hero-content .content-desc .text {
            font-size: 6vw;
            max-width: 75vw;

            > .text-item-bg {
                > .text-bg {
                    height: 3vw;
                }
            }
        }
    }

    @media only screen and (max-width: 1349px) {
        .home-hero-content .content-title .title-row {
            .supported {
                padding: 80px 0 10px 40px;
            }

            .svg-star svg {
                width: 80px;
                height: 90px;
            }
        }
    }

    @media only screen and (max-width: 1199px) {
        padding-bottom: 0;

        .home-hero-content {
            padding: 0 50px;
            position: relative;
            top: 0;
        }

        .home-hero-marquee {
            height: 13vw;
            padding: 0;
            position: relative;
            top: 0;
            margin: 150px 0;

            .home-hero-marquee-row {
                .home-hero-marquee-item {
                    > span {
                        font-size: 3.3vw;
                        line-height: normal;

                        span:nth-child(1) {
                            margin: 0 3vw;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 991px) {
        .home-hero-content .content-title {
            padding-top: 190px;

            .title-row {
                .title {
                    font-size: 5.8vw;
                    display: flex;
                    align-items: center;
                    line-height: normal;
                }

                .svg-star {
                    svg {
                        width: 70px;
                        height: 110px;
                    }

                    padding: 0px 30px 0 40px;
                }

                .supported {
                    padding-left: 20px;
                    padding-top: 40px;
                    padding-bottom: 20px;

                    .support-item img {
                        height: 34px;
                        position: relative;
                        bottom: -7px;
                    }

                    > span:nth-child(3) {
                        width: 20px;
                        margin: 0 15px -30px 10px;
                    }
                }

                &:nth-child(1) .title > span {
                    > svg {
                        height: 10vw;
                    }
                }

                .title > .support-img {
                    transform: scale(0.6);
                    right: 30px;
                }
            }
        }

        .home-hero-content .content-desc {
            padding-top: 90px;
        }
    }

    @media only screen and (max-width: 767px) {
        .home-hero-content {
            padding: 0 24px;

            .content-title .title-row .title > .support-img {
                transform: scale(0.4);
                right: 10px;
            }
        }

        .home-hero-content .content-title {
            .title-row {
                &:nth-child(1) .title > span > svg {
                    left: -36px;
                    top: -24px;
                    width: 26vw;
                    height: 13vw;
                }

                .svg-star {
                    svg {
                        width: 30px;
                        height: 60px;
                    }

                    padding: 0px 15px 0 20px;
                }

                .title {
                    font-size: 5.5vw;
                }

                .supported {
                    .support-item img {
                        height: 19px;
                        bottom: 0;
                    }

                    > span:nth-child(3) {
                        margin: 0 10px -5px 5px;
                        width: 10px;
                    }

                    padding-top: 30px;
                    padding-bottom: 10px;
                }

                &:nth-child(1) .title > span > svg path {
                    stroke-width: 3px;
                }
            }

            > p {
                line-height: 1.3;
                max-width: 250px;
                margin-top: 20px;
                font-size: 10px;
            }
        }

        .home-hero-content .content-title {
            padding-top: 150px;
        }

        .home-hero-content .content-desc {
            padding-top: 70px;
        }

        .home-hero-marquee {
            margin: 90px 0;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .home-hero .home-hero-content .content-title .title-row .title > .support-img {
        > .img-item {
            transform: translate(200px, -50px);
        }

        height: 180px;

        img {
        }
    }

    .marquee-container .marquee-item .item-wrapper span img {
        height: 25px;
    }

    .home-hero .marquee-container .marquee-item {
        padding: 30px 0;

        &:nth-child(2) {
            margin-top: -90px;
        }
    }

    .world-info-list .list-img .list-img-sticky img {
        transform: translate(100px, -43%);
    }

    .world-info-list .list-content {
        margin-top: 200px;
        margin-left: 100px;

        .content-item {
            .item-title {
                font-size: 35px;
            }

            p {
                font-size: 18px;
                line-height: 1.3;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .world-info-list .list-content {
        margin: 150px 24px 0;

        .content-item {
            .item-title {
                font-size: 28px;
            }

            p {
                font-size: 15px;
            }

            > span {
                font-size: 10px;
            }

            max-width: initial;
            height: auto;
        }
    }

    .world-info-list {
        .list-img {
            display: none;
        }

        .list-content .content-item img {
            margin-left: 24px;
        }
    }
}
 // yukarı butonu
.buton-up{
    display: none;
    align-items: center;
    position: fixed;
    bottom: 7%;
    right: 5%;
    z-index: 99;


    @media(max-height: 700px) {
            // top: 530px;
            bottom:20px;
            right: 10%;
    }
    @media(max-height: 600px) {
        top: 390px;
        right: 10%;
}

    .header-button {
        margin-left: 32px;
        border: 2px solid rgba(255,255,255,0.8);
        border-radius: 8px;
        background: transparent;

        a {
            font-size: 16px;
            color: #fff;
            border-radius: 8px;
            position: relative;
            overflow: hidden;

            &:after {
                content: "";
                position: absolute;
                top: 1px;
                left: 1px;
                right: 1px;
                bottom: 1px;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            > span {
                display: block;
                padding: 13px 32px;
                position: relative;
                z-index: 1;
                cursor: pointer;

                &:before {
                    content: "";
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    right: 1px;
                    bottom: 1px;
                    border-radius: 4px;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                        radial-gradient(
                            90.16% 143.01% at 15.32% 21.04%,
                            rgba(165, 239, 255, 0.2) 0%,
                            rgba(110, 191, 244, 0.0447917) 77.08%,
                            rgba(70, 144, 213, 0) 100%
                        );
                }

                > span {
                    position: relative;
                    z-index: 2;
                }
            }
        }
    }
}

