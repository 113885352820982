/* Top Info */
.top-info {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    background: #0642ff;
    height: 40px;
    z-index: 5;

    .top-info-wrapper {
        position: absolute;
        width: 150%;
        height: 40px;
        left: -50%;
        top: 0;
        background: #0642ff;
        z-index: 1;
    }

    .top-info-wrapper span {
        white-space: nowrap;
        position: absolute;
        height: 40px;
        background: #0642ff;
        padding-left: 4px;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        line-height: normal;
    }

    .top-info-wrapper .top-info-boxes {
        position: relative;
        right: -300px;
    }

    @media (min-width: 1200px) {
        transform: translate(0, -100%);

        .top-info-wrapper span {
            opacity: 0;
        }
    }

    @media (max-width: 767px) {
        height: 30px;

        .top-info-wrapper {
            span {
                height: 30px;
                font-size: 11px;
                width: 240px;
            }

            .top-info-boxes {
                right: 240px;
            }

            height: 30px;
        }
    }
}

header {
    position: absolute;
    padding-left: 90px;
    padding-right: 90px;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 5;

    .header-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-logo {
            display: flex;
            align-items: center;

            a img {
                width: auto;
                margin-right: 8px;
            }

            a:nth-child(2) {
                border-radius: 4px;
                position: relative;
                overflow: hidden;
                margin-left: 1rem;
                &:after {
                    content: "";
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    right: 2px;
                    bottom: 2px;
                    border-radius: 4px;
                    background: #000;
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 8px;
                    background: radial-gradient(
                            80.69% 208.78% at 108.28% 112.58%,
                            #eabfff 0%,
                            rgba(135, 38, 183, 0) 100%
                        ),
                        radial-gradient(80.38% 222.5% at -13.75% -12.36%, #98f9ff 0%, rgba(255, 255, 255, 0) 100%);
                }

                > span {
                    display: flex;
                    align-items: center;
                    height: 26px;
                    line-height: 28px;
                    padding: 5px 12px;
                    position: relative;
                    z-index: 1;
                    color: #fff;
                    font-size: 16px;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        right: 2px;
                        bottom: 2px;
                        border-radius: 4px;
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                            radial-gradient(
                                90.16% 143.01% at 15.32% 21.04%,
                                rgba(165, 239, 255, 0.2) 0%,
                                rgba(110, 191, 244, 0.0447917) 77.08%,
                                rgba(70, 144, 213, 0) 100%
                            );
                    }

                    > span {
                        position: relative;
                        z-index: 2;
                    }
                }
            }
        }

        .header-right {
            display: flex;
            align-items: center;

            .header-button {
                margin-left: 32px;

                a {
                    font-size: 16px;
                    color: #fff;
                    border-radius: 8px;
                    position: relative;
                    overflow: hidden;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 1px;
                        left: 1px;
                        right: 1px;
                        bottom: 1px;
                        border-radius: 8px;
                        background: #000;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: 8px;
                        background: radial-gradient(
                                80.69% 208.78% at 108.28% 112.58%,
                                #eabfff 0%,
                                rgba(135, 38, 183, 0) 100%
                            ),
                            radial-gradient(80.38% 222.5% at -13.75% -12.36%, #98f9ff 0%, rgba(255, 255, 255, 0) 100%);
                    }

                    > span {
                        display: block;
                        padding: 13px 32px;
                        position: relative;
                        z-index: 1;

                        &:before {
                            content: "";
                            position: absolute;
                            top: 1px;
                            left: 1px;
                            right: 1px;
                            bottom: 1px;
                            border-radius: 4px;
                            background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                                radial-gradient(
                                    90.16% 143.01% at 15.32% 21.04%,
                                    rgba(165, 239, 255, 0.2) 0%,
                                    rgba(110, 191, 244, 0.0447917) 77.08%,
                                    rgba(70, 144, 213, 0) 100%
                                );
                        }

                        > span {
                            position: relative;
                            z-index: 2;
                        }
                    }
                }
            }
        }
    }

    .header-menu {
        > ul {
            display: flex;
            align-items: center;

            > li {
                position: relative;
                padding-bottom: 40px;
                margin-bottom: -40px;

                > span:nth-child(2) {
                    position: absolute;
                    top: -16px;
                    right: -13px;
                    filter: blur(0.5px);
                    transform: rotate(23.8deg);
                    font-size: 11px;
                }

                > span:nth-child(3) {
                    position: absolute;
                    top: -33px;
                    right: -12px;
                    filter: blur(1px);
                    transform: rotate(-20.07deg);
                    font-size: 7px;
                }

                > ul {
                    position: absolute;
                    top: 40px;
                    left: -12px;
                    opacity: 0;

                    a:nth-child(2) {
                        transition: 0.4s;

                        > span {
                            display: block;
                            border: 1px solid #ffffff;
                            padding: 4px 11px;
                            font-size: 12px;
                            letter-spacing: -0.01em;
                            color: #ffffff;
                        }
                    }

                    visibility: hidden;
                    background: #0c0c26;
                    box-shadow: 0px 6px 20px 0px rgb(255 255 255 / 8%);
                    border-radius: 16px;
                    padding: 16px 14px;
                    min-width: 160px;
                }

                + li {
                    margin-left: 32px;
                }

                > a {
                    font-size: 16px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        bottom: -10px;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background: #fff;
                        transform: scaleX(0);
                        transform-origin: bottom right;
                        transition: transform 0.2s ease-out;
                    }

                    > span {
                        margin-left: 6px;
                    }

                    svg {
                        margin-top: 3px;
                        margin-left: 4px;
                    }
                }
            }
        }

        > ul > li {
            &:hover > ul {
                opacity: 1;
                visibility: visible;
            }

            > ul > li {
                + li {
                    margin-top: 8px;
                }

                a {
                    padding: 4px 0;
                    display: block;
                    color: #fff;
                }
            }
        }
    }

    .mobile-menu-button {
        display: none;
        width: 45px;
        margin-top: -5px;

        > span {
            display: block;
            width: 100%;
            height: 1px;
            background: #fff;

            + span {
                margin-top: 8px;
            }
        }

        @media only screen and (max-width: 991px) {
            display: block;
        }
    }

    @media (hover: hover) {
        // .header-row .header-logo a:nth-child(2):hover > span {
        //     background: #fff;
        //     color: #000;
        // }

        .header-menu > ul > li > a {
            &:hover:before {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }

        // .header-row .header-right .header-button a {
        //     &:hover:before {
        //         transform: scaleX(1);
        //         transform-origin: bottom left;
        //     }
        // }
    }

    @media (min-width: 1200px) {
        .header-row {
            .header-menu {
                > ul > li {
                    transform: translate(0px, -30px);
                    opacity: 0;
                }
            }

            .header-right {
                .header-button {
                    transform: translate(0px, -40px);
                    opacity: 0;
                }
            }

            .header-logo a {
                img {
                    transform: translate(0px, -150%);
                    opacity: 0;
                }

                &:nth-child(2) {
                    transform: translate(0px, -150%);
                    opacity: 0;
                }
            }
        }
    }

    @media only screen and (max-width: 1199px) {
        padding-left: 50px;
        padding-right: 50px;

        
    }

    @media (max-width: 991px) {
        .header-row {
            .header-right,
            .header-menu {
                display: none;
            }
           .header-logo > a:nth-child(2) {
                display: none;
            }
        }
    }

    @media (max-width: 767px) {
        top: 50px;
        padding-left: 24px;
        padding-right: 24px;

        .header-row .header-logo a img {
            height: 30px;
        }
    }
}

// Mobile Menu
.mobile-menu {
    display: none;

    @media only screen and (max-width: 991px) {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to top, #000000e8 70%, #0026ff59);
        z-index: 4;
        padding: 100px 50px 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        opacity: 0;
        visibility: hidden;

        > ul {
            &:nth-child(2) {
                display: flex;
                align-items: center;
                margin: 40px -20px 0;

                li {
                    a {
                        color: #fff;
                        font-size: 4vw;
                        padding: 10px;
                        margin: -10px 10px;
                        display: block;
                    }
                }
            }

            &:nth-child(1) {
                li {
                    a {
                        color: #fff;
                        font-size: 6vw;
                        padding: 10px;
                        display: block;
                        margin: 0 0 10px;
                    }

                    ul {
                        padding-left: 10px;
                        display: none;

                        li a {
                            font-size: 5vw;
                        }
                    }
                }

                margin: 0 -10px;
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 60vh;

                &::-webkit-scrollbar {
                    width: 0;
                }
            }

            > li {
                transform: translate(0px, 20px);
                opacity: 0;
                visibility: hidden;

                ul {
                    li {
                        transform: translate(0px, 10px);
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 767px) {
        padding: 100px 24px 50px;

        > ul {
            &:nth-child(2) {
                li {
                    a {
                        font-size: 5vw;
                    }
                }
            }

            &:nth-child(1) {
                li {
                    a {
                        font-size: 7vw;
                    }

                    ul {
                        li a {
                            font-size: 6vw;
                        }
                    }
                }
            }
        }
    }
}
