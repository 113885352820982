@font-face {
    font-family: 'ObjectSans';
    src: local('ObjectSans Bold'), local('ObjectSans-Bold'),
        url('../font/ObjectSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'ObjectSans';
    src: local('ObjectSans Thin'), local('ObjectSans-Thin'),
        url('../font/ObjectSans-Thin.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'ObjectSans';
    src: local('ObjectSans Regular'), local('ObjectSans-Regular'),
        url('../font/ObjectSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}