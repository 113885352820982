// Title
@keyframes clientAstronaut {
    0%,
    100% {
        transform: translate(0px, -55%);
    }

    50% {
        transform: translate(0px, -50%);
    }
}

@media only screen and (min-width: 1200px) {
    .home-reviews-title {
        img {
            animation: clientAstronaut 8s infinite both ease-in-out;
        }
    }
}

.home-reviews-title {
    font-size: 94px;
    color: #ffffff;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1200px;
        top: auto;
        transform: translate(0px, -50%);
        left: -15%;
        background-image: url("../img/home-gradient-bg.png");
        background-size: contain;
        background-position: top left;
        background-repeat: no-repeat;
        pointer-events: none;
        z-index: 1;
    }

    img {
        position: absolute;
        left: 290px;
        top: 50%;
        transform: translate(0px, -50%);
        z-index: 1;
        pointer-events: none;
    }

    span {
        position: relative;
    }

    svg {
        position: absolute;
        top: -40px;
        left: -50px;
    }

    @media only screen and (max-width: 767px) {
        font-size: 11vw;
        margin-bottom: 4vw;

        svg {
            width: 50%;
            height: auto;
            left: -10px;
            top: -17px;
        }

        img {
            left: auto;
            top: 0px;
            right: -100px;
            transform: translate(0px, -50%);
        }
    }
}

// Desc
.home-reviews-desc {
    font-size: 24px;
    color: #606277;
    margin-top: 20px;
    max-width: 550px;

    @media only screen and (max-width: 767px) {
        font-size: 18px;
        margin-bottom: 50px;
    }
}

// Slider
.home-reviews {
    padding-top: 350px;
    overflow: hidden;
}

.home-reviews-title,
.home-reviews-desc {
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 32px;
}

.home-reviews .home-reviews-slider {
    margin-top: 130px;
    overflow: hidden;
    position: relative;
    z-index: 2;

    .swiper-wrapper {
        align-items: center;
    }

    .home-reviews-slider-item {
        display: flex;
        position: relative;
        border-radius: 32px;
        transition: 0.6s;
        transform: scale(0.9);
        opacity: 0.4;

        &:after {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            right: 2px;
            bottom: 2px;
            border-radius: 32px;
            background: #000;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 32px;
            background: radial-gradient(80.69% 208.78% at 108.28% 112.58%, #eabfff 0%, rgba(135, 38, 183, 0) 100%),
                radial-gradient(80.38% 222.5% at -13.75% -12.36%, #98f9ff 0%, rgba(255, 255, 255, 0) 100%);
        }

        .home-reviews-slider-item-desc {
            padding: 48px;
            border-radius: 16px;
            overflow: hidden;
            margin: 16px;
            max-width: 314px;
            margin-left: auto;
            position: relative;
            z-index: 1;

            p {
                font-weight: 700;
                font-size: 21px;
                line-height: 1.4;
                color: #a5a5a5;
                position: relative;
                z-index: 1;

                > span {
                    color: #fff;
                }
            }

            &:after {
                content: "";
                position: absolute;
                top: 2px;
                left: 2px;
                right: 2px;
                bottom: 2px;
                border-radius: 16px;
                background: #000;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 16px;
                background: radial-gradient(80.69% 208.78% at 108.28% 112.58%, #eabfff 0%, rgba(135, 38, 183, 0) 100%),
                    radial-gradient(80.38% 222.5% at -13.75% -12.36%, #98f9ff 0%, rgba(255, 255, 255, 0) 100%);
            }
        }

        .home-reviews-slider-item-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 48px;
            position: relative;
            z-index: 1;

            img {
                &.small-img {
                    max-width: 100px;
                }
            }
        }
    }

    .swiper-container {
        margin: 0 -25%;
    }

    .home-reviews-slider-item .home-reviews-slider-item-info .home-reviews-slider-item-info-profile {
        .home-reviews-slider-item-info-profile-text span {
            &:nth-child(1) {
                font-size: 24px;
                display: block;
                color: #ffffff;
            }

            &:nth-child(2) {
                font-size: 18px;
                display: block;
                color: #606277;
                margin-top: 8px;
            }
        }

        .home-reviews-slider-item-info-profile-img {
            margin-right: 16px;
            min-width: 80px;
            height: 80px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 2px;
                left: 2px;
                right: 2px;
                bottom: 2px;
                border-radius: 16px;
                background: #000;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 16px;
                background: radial-gradient(80.69% 208.78% at 108.28% 112.58%, #eabfff 0%, rgba(135, 38, 183, 0) 100%),
                    radial-gradient(80.38% 222.5% at -13.75% -12.36%, #98f9ff 0%, rgba(255, 255, 255, 0) 100%);
            }

            img {
                filter: grayscale(1);
                position: relative;
                z-index: 1;
            }
        }

        display: flex;
        align-items: center;
    }

    .swiper-slide-active .home-reviews-slider-item {
        transform: scale(1);
        opacity: 1;
    }

    .swiper-slide-next .home-reviews-slider-item {
        transform-origin: left;
    }

    .swiper-slide-prev .home-reviews-slider-item {
        transform-origin: right;
    }
}

@media only screen and (max-width: 1199px) {
    .home-reviews .home-reviews-slider .home-reviews-slider-item {
        display: block;
        padding-bottom: 1px;

        .home-reviews-slider-item-info {
            img {
            }

            display: block;

            .home-reviews-slider-item-info-profile {
                margin-top: 32px;
            }

            img {
            }
        }

        .home-reviews-slider-item-desc {
            margin: 16px;
            max-width: inherit;
        }
    }
}

@media only screen and (max-width: 991px) {
    .home-reviews-title:before {
        top: 270%;
    }

    .home-reviews .home-reviews-slider .swiper-container {
        margin: 0 -70%;
    }
}

@media only screen and (max-width: 767px) {
    .home-reviews-title:before {
        top: 430px;
    }

    .home-reviews .home-reviews-slider .home-reviews-slider-item {
        .home-reviews-slider-item-desc {
            p {
                font-size: 15px;
            }

            padding: 32px;
        }

        .home-reviews-slider-item-info {
            padding: 32px;

            .home-reviews-slider-item-info-profile {
                .home-reviews-slider-item-info-profile-text span {
                    &:nth-child(1) {
                        font-size: 18px;
                    }

                    &:nth-child(2) {
                        font-size: 15px;
                    }
                }

                .home-reviews-slider-item-info-profile-img {
                    min-width: 60px;
                    height: 60px;
                    overflow: hidden;

                    img {
                        margin-top: auto;
                        height: 70%;
                        margin-bottom: 9px;
                    }
                }
            }

            > img {
                height: 40px;
            }
        }
    }
}
