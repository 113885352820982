footer {
    background: #07071c;
    overflow: hidden;
    font-weight: 300;

    &.home-footer {
        background: black;
        position: relative;
        overflow: initial;

        .footer-earth {
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            height: 70vw;
            background-size: contain;
            background-position: bottom;
            background-repeat: no-repeat;
            background-image: url("../img/home-footer-earth.webp");
            pointer-events: none;
        }

        .footer-home-container {
            position: relative;
            z-index: 1;
            border-top: 1px solid #fff;
        }

        .footer-row {
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;

            .footer-logo {
                flex: 0 0 350px;
                padding: 30px 40px 50px 50px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                p {
                    font-size: 14px;
                    color: #fff;
                    line-height: 1.4;

                    > span {
                        font-weight: 700;

                        &.sm {
                            font-size: 12px;
                        }
                    }
                }

                img {
                    max-width: 50px;
                }
            }

            .footer-menu {
                flex: 0 0 380px;
                border-left: 1px solid #fff;
                border-right: 1px solid #fff;
                display: flex;
                flex-direction: column;

                a {
                    + a {
                        border-top: 1px solid #fff;
                    }

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 1;

                    span {
                        font-size: 16px;
                        color: #fff;
                        transition: 0.4s;
                    }

                    &:hover {
                        span {
                            opacity: 0.5;
                        }
                    }
                }
            }

            .footer-marquee-container {
                flex: 1;

                .footer-marquee {
                    padding: 50px 0;
                    position: relative;
                    overflow: hidden;
                    height: 80px;

                    .footer-marquee-row {
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);
                        left: 0;
                        z-index: 1;
                        white-space: nowrap;
                        will-change: transform;

                        &.marquee-2 {
                            left: auto;
                            right: 0;
                        }

                        .footer-marquee-item {
                            position: relative;
                            display: inline-flex;
                            align-items: center;
                            white-space: nowrap;

                            a {
                                white-space: nowrap;
                                font-size: 80px;
                                color: white;
                                display: inline-block;
                                transition: 0.4s;
                                padding: 0 30px;

                                &:hover {
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
            }
        }

        .footer-copyright {
            border-top: 1px solid #fff;
            padding: 50px 0;

            .footer-container {
                max-width: 100%;
                padding: 0 70px;

                p,
                a {
                    color: #fff;
                }
            }
        }

        @media (max-width: 1470px) {
            .footer-row {
                .footer-menu {
                    flex: 0 0 320px;
                }

                .footer-marquee-container {
                    .footer-marquee {
                        height: 70px;

                        .footer-marquee-row {
                            .footer-marquee-item {
                                a {
                                    font-size: 70px;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 1199px) {
            .footer-row {
                .footer-logo {
                    padding: 40px 70px;

                    p {
                        margin-top: 100px;
                    }
                }

                .footer-menu {
                    border-right: 0;
                    flex: 1;
                }

                .footer-marquee-container {
                    flex: 0 0 100%;
                    border-top: 1px solid #fff;
                    padding-top: 10px;

                    .footer-marquee {
                        .footer-marquee-row .footer-marquee-item a {
                            font-size: 50px;
                        }

                        height: 50px;
                        padding: 40px 0;
                    }
                }
            }

            .footer-copyright .footer-container {
                padding: 0 60px;
            }
        }

        @media (max-width: 991px) {
            .footer-row {
                .footer-logo {
                    padding: 40px 50px;
                }
            }

            .footer-copyright .footer-container {
                padding: 0 40px;
            }
        }

        @media (max-width: 767px) {
            .footer-row {
                display: block;

                .footer-logo {
                    padding: 40px 32px;

                    p {
                        margin-top: 40px;

                        br {
                            display: none;
                        }
                    }
                }

                .footer-menu {
                    border-left: 0;
                    border-top: 1px solid #fff;
                    flex-direction: row;

                    a {
                        padding: 50px 0;

                        span {
                            font-size: 20px;
                        }

                        + a {
                            border-top: 0;
                            border-left: 1px solid #fff;
                        }
                    }
                }

                .footer-marquee-container {
                    padding: 8px 0 13px;

                    .footer-marquee {
                        .footer-marquee-row .footer-marquee-item a {
                            font-size: 24px;
                            padding: 0 10px;
                        }

                        height: 24px;
                        padding: 24px 0;

                        + .footer-marquee {
                            padding-top: 0;
                        }
                    }
                }
            }

            .footer-copyright {
                padding: 24px 0;

                .footer-container {
                    padding: 0;
                }
            }
        }
    }

    .footer-copyright {
        position: relative;
        padding: 30px 0;
        border-top: 1px solid rgba(255, 255, 255, 0.2);

        .footer-container {
            p,
            a {
                font-size: 15px;
                color: rgba(214, 211, 255, 0.7);
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -10px;

                li {
                    margin: 0 10px;

                    a {
                        transition: 0.4s;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }

            a > span {
                font-weight: 600;
            }
        }
    }

    .footer-container {
        max-width: 1280px;
        margin: 0 auto;
        position: relative;
        padding: 0 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .footer-circle {
            position: absolute;
            top: 0;
            width: 900px;
            height: 600px;
            transform: translate(0px, -13%);
            border-radius: 100%;
            background: #100c5d;
            filter: blur(50px);
            opacity: 0.3;
            pointer-events: none;

            &.circle-1 {
                right: 0;
                left: auto;
            }

            &.circle-2 {
                left: -300px;
                right: auto;
                top: -100px;
            }
        }

        .footer-row {
            padding: 60px 0 45px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            position: relative;
            width: 100%;

            .footer-col {
                p {
                    font-size: 15px;
                    color: rgba(214, 211, 255, 0.7);
                    line-height: 1.3;
                }

                .footer-logo {
                    margin-bottom: 40px;

                    img {
                        width: 200px;
                    }
                }

                &:nth-child(1) {
                    > a {
                        margin-top: 51px;
                        display: inline-block;
                        font-size: 15px;
                        border-radius: 10px;
                        background: #fff;
                        border: 1px solid #fff;
                        color: #000;
                        padding: 15px 24px;
                        transition: 0.3s;

                        @media (hover: hover) {
                            &:hover {
                                background: #fff0;
                                color: #fff;
                            }
                        }
                    }

                    p {
                        max-width: 200px;
                    }
                }

                ul {
                    li {
                        a {
                            font-size: 15px;
                            color: rgba(214, 211, 255, 0.7);
                            display: inline-block;
                            padding: 6px 0;
                            transition: 0.3s;

                            @media (hover: hover) {
                                &:hover {
                                    color: #fff;
                                }
                            }
                        }

                        + li {
                            margin-top: 14px;
                        }
                    }
                }

                .footer-contact {
                    max-width: 220px;

                    > span {
                        font-size: 20px;
                        color: #fff;
                        display: block;
                        margin-bottom: 30px;
                    }

                    a {
                        display: inline-block;
                        font-size: 15px;
                        padding: 3px 0;
                        color: rgba(214, 211, 255, 0.7);
                        transition: 0.3s;

                        + a {
                            margin-top: 8px;
                        }

                        @media (hover: hover) {
                            &:hover {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 991px) {
        .footer-copyright {
            .footer-container {
                display: block;
                text-align: center;

                ul {
                    margin: 40px 0;
                    justify-content: center;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .footer-copyright {
            padding: 24px 0;

            .footer-container {
                ul {
                    margin: 20px 0;

                    li a {
                        display: inline-block;
                        padding: 5px 0;
                    }
                }

                p {
                    margin-bottom: 16px;
                }

                p,
                a {
                    font-size: 12px;
                }
            }
        }

        .footer-container .footer-row > .footer-col {
            flex: 0 0 100%;

            &:nth-child(2) {
                margin: 32px 0;
            }
        }
    }
}
