:root {
    --white: #afafaf;
    --bodyColor: #292a2b;
    --bgFormEls: hsl(0, 0%, 14%);
    --bgFormElsFocus: hsl(0, 7%, 20%);
}

.contact-banner {
    position: relative;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;


    .pricing-banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #07071c;
    }

    .s-container {
        text-align: center;
        flex-wrap: wrap;
        position: relative;

        h1 {
            font-size: 55px;
            letter-spacing: -0.04em;
            font-weight: normal;
            color: #fff;

            span {
                color: #e7a1ee;
            }
        }
    }

    .switch-plan {
        margin-top: 21px;
    }

    @media (min-width: 1200px) {
        .pricing-banner-bg {
            //  transform: translate(0%, -100%);
            //  overflow: hidden;
            //test edilecek
        }

        .s-container h1,
        .switch-plan {
            transform: translate(0px, 30px);
            opacity: 0;
        }
    }

    @media (max-width: 991px) {
        .s-container h1 {
            font-size: 44px;
        }
    }

    @media (max-width: 767px) {
        height: 250px;

        .s-container h1 {
            font-size: 32px;
            max-width: 260px;
        }
    }
}



.contact-content {
    margin-top: -90px;
    padding-bottom: 120px;
    position: relative;
    //background-image: url("../img/home-gradient-bg.png");
    background-color: #07071c;

    .content-plan-container {
        position: sticky;
        top: 0;
        z-index: 1;

        .content-plan {
            border-bottom: 1px solid rgb(241 241 241 / 0%);
            transition: 0.4s;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
                bottom: 0;
                width: 0%;
                background-color: #fff;
                transition: 0.4s;
            }

            &.active {
                border-bottom-color: rgb(241 241 241 / 100%);

                &:before {
                    width: 100%;
                }

                .s-container {
                    border-color: #fff0;
                }

                .s-container>span,
                .plan-row .plan-title>span {
                    opacity: 0;
                }

                .plan-item {
                    .item-header p {
                        margin-bottom: -60px;
                    }

                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        .item-header p {
                            opacity: 0;
                            // height: 0;
                        }
                    }

                    .price {
                        >span:nth-child(1) {
                            opacity: 0;
                            // height: 0;
                        }

                        margin-top: -10px;
                    }

                    a {
                        margin-top: 20px;
                    }
                }
            }

            .s-container {
                background: #fff;
                border: 1px solid #f1f1f1;
                border-radius: 10px;
                padding: 0 0;
                transition: 0.4s;
                position: relative;

                >span {
                    display: block;
                    padding-left: 18px;
                    margin-top: 20px;
                    margin-bottom: -36px;
                    transition: 0.4s;
                }
            }

            .plan-row {
                display: flex;
                margin-top: -17px;
                margin-bottom: -1px;
                padding: 0px 18px;

                .plan-title {
                    flex: 0 0 240px;
                    display: flex;
                    align-items: center;

                    >span {
                        font-size: 15px;
                        color: #171010;
                        display: block;
                        transition: 0.4s;
                    }
                }
            }

            .plan-item {
                flex: 0 0 calc(15.9% - 27px);
                padding: 40px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                h2 {
                    font-size: 20px;
                    color: #000;
                    font-weight: normal;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 15px;
                    letter-spacing: -0.04em;
                    line-height: 22px;
                    color: #131211;
                    transition: 0.4s;
                }

                .price {
                    margin-top: 42px;
                    transition: 0.4s;

                    >span {
                        transition: 0.4s;

                        &:nth-child(1) {
                            font-size: 15px;
                            letter-spacing: -0.04em;
                            color: #131211;
                            display: block;
                        }

                        &:nth-child(2) {
                            font-size: 35px;
                            letter-spacing: -0.04em;
                            color: #131211;

                            >span:nth-child(1) {
                                font-size: 15px;
                            }
                        }
                    }
                }

                >span {
                    margin-top: 10px;
                    display: block;
                    font-size: 15px;
                    color: #131211;
                    transition: 0.4s;
                }

                a {
                    font-weight: bold;
                    font-size: 15px;
                    color: #fff;
                    display: block;
                    background: #0063f7;
                    border-radius: 10px;
                    padding: 16px;
                    text-align: center;
                    margin-top: 25px;
                    transition: 0.4s;

                    @media (hover: hover) {
                        &:hover {
                            background: #3d87ff;
                        }
                    }
                }
            }

            .plan-item+.plan-item {
                border-left: 2px solid #f1f1f1;
                margin-left: 18px;
                padding-left: 18px;
            }
        }
    }

    .content-body {
        margin-top: 90px;

        .body-item {
            +.body-item {
                margin-top: 80px;
            }

            .item-title {
                font-size: 25px;
                letter-spacing: -0.02em;
                color: #131211;
                display: block;
                margin-bottom: 30px;
            }

            .item-list .list-item {
                .list-title {
                    display: block;
                    font-size: 15px;
                    letter-spacing: 0.03em;
                    color: #0f0808;
                }

                +.list-item {
                    margin-top: -16px;

                    .list-row .row-item {
                        border-top: none;
                        border-bottom: none;
                    }
                }

                .list-row {
                    display: flex;
                    flex-wrap: wrap;

                    .row-item {
                        flex: 0 0 calc(16% - 34px);
                        max-width: calc(16% - 34px);
                        border-top: 1px dashed #f1f1f1;
                        border-bottom: 1px dashed #f1f1f1;
                        padding: 50px 0 60px;

                        +.row-item {
                            border-right: 2px solid #f1f1f1;
                            margin-right: 18px;
                            padding-right: 18px;
                            margin-left: -18px;
                            padding-left: 18px;
                        }

                        &:first-child {
                            flex: 0 0 258px;
                            max-width: 258px;
                            border-top: none;
                            border-bottom: none;
                        }

                        &:last-child {
                            border-right: none;
                            margin-right: 0;
                            padding-right: 0;
                        }

                        span {
                            font-size: 15px;
                            letter-spacing: 0.03em;
                            color: #625f5f;
                            display: block;
                            min-height: 42px;

                            +span {
                                margin-top: 44px;
                                line-height: 1.4;
                            }
                        }
                    }
                }
            }
        }
    }

    .content-info {
        max-width: 580px;
        margin: 100px auto 0;
        text-align: center;

        p {
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #625f5f;
        }
    }

    @media (min-width: 1200px) {
        .content-plan-container {
            transform: translate(0px, 50px);
            opacity: 0;
        }

        .content-body .body-item {
            .item-title {
                transform: translate(0px, 20px);
                opacity: 0;
            }

            .item-list .list-item .list-row .row-item {
                transform: translate(0px, 50px);
                opacity: 0;
            }
        }

        .content-info {
            transform: translate(0px, 30px);
            opacity: 0;
        }
    }

    @media (max-width: 1550px) {
        .content-plan-container .content-plan {
            .plan-item {
                padding: 30px 0;

                h2 {
                    font-size: 18px;
                }

                .price {
                    margin-top: 20px;
                }

                a {
                    font-size: 13px;
                    margin-top: 14px;
                }

                .price>span {
                    &:nth-child(2) {
                        >span {

                            &:nth-child(2),
                            &:nth-child(3) {
                                margin-bottom: -6px;
                            }
                        }

                        font-size: 30px;
                        display: flex;
                        align-items: flex-end;
                        margin-bottom: 4px;
                    }

                    &:nth-child(1) {
                        margin-bottom: 8px;
                    }
                }
            }

            &.active .plan-item {
                .price {
                    margin-top: -30px;
                }

                a {
                    margin-top: 10px;
                }
            }
        }

        .content-body .body-item {
            .item-list .list-item .list-row {
                justify-content: center;
            }

            .item-title {
                margin: 0 8px 30px;
            }
        }
    }

    @media (max-width: 1350px) {
        .s-container {
            max-width: 1200px;
        }
    }

    @media (max-width: 1199px) {
        .content-plan-container .content-plan {
            .plan-row {
                margin-top: 0;

                .plan-title {
                    display: none;
                }
            }

            .plan-item {
                flex: 0 0 calc(19.7% - 27px);
            }

            .s-container {
                max-width: 990px;

                >span {
                    display: none;
                }
            }
        }

        .content-body .body-item {
            .item-title {
                margin-left: 0;
            }

            .item-list .list-item .list-row {
                justify-content: initial;

                .row-item:first-child {
                    flex: 0 0 180px;
                    max-width: 180px;
                    padding-right: 20px;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .content-plan-container {
            position: relative;
        }

        .content-body .body-item .item-list .list-item .list-row {
            overflow-x: auto;
            overflow-y: hidden;
            flex-wrap: nowrap;
            margin: 0 -32px;
            padding-left: 32px;

            &::-webkit-scrollbar {
                height: 0;
            }

            .row-item {
                flex: 0 0 180px;
                max-width: 180px;

                &:first-child {
                    flex: 0 0 290px;
                    max-width: 290px;
                }
            }
        }

        .content-plan {
            .plan-item {
                flex: 0 0 calc(25% - 29px);
                padding-top: 70px;
            }
        }
    }

    @media (max-width: 767px) {
        .content-plan {
            .s-container {
                max-width: 100%;
                margin: 0 10px;

                >span {
                    display: none;
                }

                .plan-row {
                    flex-wrap: wrap;
                    margin-top: 0;
                }

                .plan-item {
                    flex: 0 0 calc(50% - 19px);
                    padding: 30px 0;

                    &:nth-child(4) {
                        margin-left: -18px;
                        margin-right: -18px;
                        padding-left: 18px;
                        padding-right: 18px;
                        border-left: none;
                        border-top: 2px solid #f1f1f1;
                    }

                    &:nth-child(5) {
                        border-top: 2px solid #f1f1f1;
                        margin-right: -20px;
                        flex: 0 0 calc(50% - 18px);
                        padding-right: 18px;
                    }

                    &:last-child {
                        padding: 30px 18px;
                        margin: 0 -18px;
                        border-top: 2px solid #f1f1f1;
                        border-left: none;
                        flex: 0 0100%;
                    }

                    a {
                        font-size: 13px;
                    }
                }
            }
        }

        .content-body .body-item .item-list .list-item .list-row {
            margin: 0 -24px;
            padding-left: 24px;

            .row-item {
                padding: 30px 0;

                span+span {
                    margin-top: 30px;
                }
            }
        }
    }
}


// form css
/* Style inputs, select elements and textareas */
input[type=text],
[type=email],
[type=tel],
textarea,
select {
    width: 100%;
    max-width: 515px;
    padding: 12px 35px;
    border: 1px solid #625F5F;
    border-radius: 18px;
    box-sizing: border-box;
    resize: vertical;
    //color: black;
    background-color: #FFFFFF;
}

/* Style the label to display next to the inputs */
label {
    padding: 12px 12px 12px 0;
    display: inline-block;
    color:#625F5F;
}

/* Style the submit button */
button[type=submit],
[type=reset] {
    background: #0063F7;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    float: right;
}
button[type=submit]{
    margin-left: 10px;
}


/* Style the container */
.contact-container {
    display: flex;
    justify-content: flex-end;
    border-radius: 5px;
    //background-image: url("../img/home-gradient-bg.png");
    background-color: #07071c;
    padding: 20px;
    padding-top: 50px;

}

/* Floating column for labels: 25% width */
.col-25 {
    float: left;
    display: flex;
    justify-content: center;
    width: 25%;
    margin-top: 6px;
}

/* Floating column for inputs: 75% width */
.col-75 {
    float: left;
    width: 75%;
    margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.alert {
    border: 5px solid red !important;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {

    .col-25,
    .col-75,
    button[type=submit],
    [type=reset] {
        width: 100%;
        margin-top: 0;
    }
    button[type=reset]{
        margin-top: 10px;
    }
   .col-25 label{
        display: none;
       }
}

:focus {
    outline: 0;
}

input[type="text"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
textarea:focus,
select:focus{
    border-color: rgb(68, 176, 255);
}

.input-groups {
    position: relative;
}

.input-groups i {
    position: absolute;
    padding: 12px;
    text-align: center;
    left: 0px;
    top: 0px;
    color: #aaa;
}
select option:first-child{
    display: none;
}
select {
    color: gray;
    cursor: pointer;
  }
  .selectbox{
    &:hover .icondown {
        color: blue !important;
      }
  }
  
  .icondown {
    pointer-events: none;
  }
.form-header {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    top: 0;
    right: 0;
    text-align: center;
}
#emailform {
    border: 2px solid transparent;
    //border-image: linear-gradient(to right, #9DFAF4, #E79DFA) 1;
    background-color:#FFFFFF;
    width: 55%;
    max-width: 516px;
    display: flex;
    flex-direction: column;
    padding-right: 46px;
    padding-bottom: 24px;
    padding-top: 44px;
    margin-right: 60px;
    margin-top: 50px;
    gap: 0.5rem;
    border-radius: 8px;
    @media (max-width: 1100px) {
        margin-top: 180px;
        margin-left: auto;
        margin-right: auto;
        padding-right: 46px;
        padding-left: 46px;
    }
    @media (max-width: 992px) {
       width: 100%;
       
    }
}

.contact-title {
    font-size: 64px;
    color: #fff;
    position: relative;
    margin-left: 52px;

    @media (max-width: 767px) {
        margin-right: auto;
        margin-left: auto;
        .title-header {
            font-size: 50px;
        }
    }

    svg {
        position: absolute;
        left: 0px;

    }

    .title-header {
        position: absolute;
        left: 30px;
        top: 40px;
        font-size: 64px;
        @media (max-width: 767px) {
                font-size: 44px;
            
        }

    }

    .title-span {
        font-size: 24px;
        font-weight: 400;
        color: #606277;
        position: absolute;
        top: 150px;
        left: 50px;
        max-width: 350px;
        @media (max-width: 767px) {
            font-size: 20px;
        
    }


    }

    .content-img{
        position: absolute;
        top:250px;
        left: 300px;
        @media (max-width: 1460px) {
            max-width: 255px;
            max-height: 255px;
            left: 200px;
    }
    @media (max-width: 1111px) {
        display: none; 
}
    }


}

.bbb{
    color: red;
    padding: 10px;
    background-color: blue;
    cursor: pointer;
}

select::-ms-expand {
    display: none; /* For Internet Explorer */
  }
  
  select {
    appearance: none; /* For Chrome */
    -webkit-appearance: none; /* For Safari */
  }
  
 .has-error{
    border: 2px solid red !important;
  }
  .errors{
    margin-top: 0.5rem;
    margin-left:0.5rem;
    color: red;
    display: none;
    font-size:0.7rem;
  }
  #errors2{
    margin-top: 0.5rem;
    margin-left:0.5rem;
    color: red;
    display: none;
    font-size:0.7rem;
  }
  .input-container{
    display: flex;
    flex-direction: column;
 
  }
  .terms{
       display: flex;
       align-items: center;
       justify-content: center;
       margin: auto;
       margin-top: 1rem;
       small{
        font-size: 0.6rem;
       }
       a{
        color: blue;
       }
  }



  .iti--separate-dial-code .iti__selected-flag {
    background-color: transparent !important;
  }

  .space-effect {
    animation: hareket 5s infinite;
  }
  
  @keyframes hareket {
    0% { transform: translateY(0); }
    50% { transform: translateY(-50px); }
    100% { transform: translateY(0); }
  }