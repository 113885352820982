// Abstract
@import "./lib/swiper.scss";

@import "./abstract/base.scss";
@import "./abstract/font.scss";

@import "./layouts/footer.scss";
@import "./layouts/header.scss";

@import "./component/price.scss";
@import "./component/marquee.scss";
@import "./component/quick-link.scss";

@import "./pages/use-case.scss";
@import "./pages/pricing.scss";
@import "./pages/homepage.scss";
@import "./pages/faq.scss";
@import "./pages/contact-us.scss";


// Load
.load-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 10;
    opacity: 0;
    visibility: hidden;

    span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translate(0px, -100%);

        &:nth-child(1) {
            background: #6b4987;
        }

        &:nth-child(2) {
            background: #7ef0f5;
        }
    }
}

.pre-loader {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    z-index: 6;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-container {
        position: relative;
        transform: translate(11px, 0px);
    }

    .logo-sprites-circle {
        width: 300px;
        height: 280px;
        transform: scale(1.2);
        opacity: 0;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    .logo-sprites-bar {
        position: absolute;
        left: 50%;
        width: 266px;
        height: 194px;
        bottom: 45px;
        transform: translate(-52%, 0px) rotate(-70deg);
        opacity: 0;
        transform-origin: 50% 80%;

        > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50.2%, -49.4%);
            width: 100%;
            height: 120%;
        }
    }

    .logo-sprites-meteor-flame {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-51%, -7px) rotate(-45deg);
        transform-origin: center;

        svg {
            width: 70px;
            height: 80px;
        }
    }

    .logo-sprites-meteor {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-16px, -15px) scale(1);

        svg {
            width: 30px;
            height: 34px;
        }
    }

    @media (max-width: 1199px) {
        display: none;
    }
}

.grecaptcha-badge { 
    visibility: hidden;
}
