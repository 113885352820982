* {
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
    user-select: none;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

// Class
.bg-black {
    background-color: #000;
}

.pin-spacer {
    pointer-events: none;
}

// Container
.s-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 32px;

    @media (max-width: 767px) {
        padding: 0 24px;
    }
}

// Body
body {
    font-family: "ObjectSans";
    line-height: 1;
    background: #fff;

    &.point-none {
        pointer-events: none;
    }

    &.overflow-hidden {
        overflow: hidden;
    }

    &.overflow-initial {
        overflow-y: auto;
        overflow-x: hidden;
    }

    &.mobile-menu-active {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        overflow: hidden;
    }
}

// Switch Price
.annual-item {
    display: none;
}

.switch-plan {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > span {
        color: #fff;
        font-size: 17px;
        font-weight: 700;
        min-width: 192px;

        > span {
            font-size: 16px;
            color: #606277;
        }
     
        &:nth-child(1) {
            text-align: right;
        }

        @media only screen and (max-width: 767px) {
            min-width: initial;
            width: 110px;
        }
    }

    .switch-item {
        position: relative;

        > input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            visibility: hidden;
        }

        label .switch-bar {
            width: 40px;
            height: 20px;
            background: #2763f7;
            border-radius: 100px;
            position: relative;
            margin: 0 10px;
            cursor: pointer;

            > span {
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(3px, -50%);
                width: 15px;
                height: 15px;
                background: white;
                border-radius: 100%;
                transition: 0.4s;
            }
        }
    }

    input:checked ~ label .switch-bar > span {
        transform: translate(22px, -50%);
    }

    .switch-item label .switch-bar {
        margin: 0 16px;
        height: 26px;
        width: 46px;

        > span {
            width: 26px;
            height: 25px;
            transform: translate(0, -50%);
        }
    }
}

// Earth
div#earth-container {
    position: fixed;
    top: 0;

    &.pointer-none {
        pointer-events: none;
    }

    @media (max-width: 1199px) {
        display: none;
    }
}
