@keyframes faqAstronaut {

    0%,
    100% {
        transform: translate(0px, -55%);
    }

    50% {
        transform: translate(0px, -50%);
    }
}

@media only screen and (min-width: 1200px) {
    .faq-title {
        img {
            animation: faqAstronaut 8s infinite both ease-in-out;
        }
    }
}

.faq-container {
    max-width: 1180px;
    margin: 0 auto;
    padding: 260px 32px 0;

    &.home-faq-container {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1200px;
            top: -27%;
            left: -15%;
            background-image: url("../img/home-gradient-bg.png");
            background-size: contain;
            background-position: top left;
            background-repeat: no-repeat;
            pointer-events: none;
            z-index: 1;
        }

        ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 90px 20px;
            position: relative;
            z-index: 2;
            margin-top: 165px;

            li> {
                span {
                    font-size: 28px;
                    line-height: 120%;
                    color: #ffffff;
                    display: block;
                }

                p {
                    font-size: 18px;
                    line-height: 150%;
                    color: #606277;
                    margin-top: 32px;

                    a {
                        font-weight: 700;
                        color: #0642ff;
                    }
                }
            }
        }

        .faq-title {
            margin-left: 0;

            img {
                position: absolute;
                left: 220px;
                top: 110%;
                transform: translate(0px, -50%);
                z-index: 1;
                pointer-events: none;
            }
        }

        .faq-desc {
            margin-left: 0;
            margin-bottom: 0;
        }

        .faq-header {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            @media only screen and (max-width: 991px) {
                display: block;
            }
        }

        .faq-btn {
            display: inline-block;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
            border-radius: 8px;
            position: relative;
            z-index: 1;
            overflow: hidden;

            &:after {
                content: "";
                position: absolute;
                top: 2px;
                left: 2px;
                right: 2px;
                bottom: 2px;
                border-radius: 8px;
                background: #000;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: radial-gradient(80.69% 208.78% at 108.28% 112.58%, #eabfff 0%, rgba(135, 38, 183, 0) 100%),
                    radial-gradient(80.38% 222.5% at -13.75% -12.36%, #98f9ff 0%, rgba(255, 255, 255, 0) 100%);
            }

            >span {
                display: block;
                padding: 13px 53px;
                white-space: nowrap;
                position: relative;
                z-index: 1;

                &:before {
                    content: "";
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    right: 2px;
                    bottom: 2px;
                    border-radius: 8px;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                        radial-gradient(90.16% 143.01% at 15.32% 21.04%,
                            rgba(165, 239, 255, 0.2) 0%,
                            rgba(110, 191, 244, 0.0447917) 77.08%,
                            rgba(70, 144, 213, 0) 100%);
                }

                >span {
                    position: relative;
                    z-index: 2;
                }
            }

            @media only screen and (max-width: 991px) {
                margin-top: 40px;
            }
        }

        @media only screen and (max-width: 1199px) {
            overflow: hidden;
        }

        @media only screen and (max-width: 991px) {
            &:before {
                top: 0;
            }
        }

        @media only screen and (max-width: 767px) {
            ul {
                display: block;
                margin-top: 60px;

                li {
                    > {
                        span {
                            font-size: 20px;
                        }

                        p {
                            font-size: 16px;
                            margin-top: 24px;
                        }
                    }

                    +li {
                        margin-top: 60px;
                    }
                }
            }

            .faq-title img {
                left: auto;
                top: 0px;
                transform: translate(0px, -50%) rotate(-35deg);
            }
        }
    }

    .faq-title {
        font-size: 94px;
        color: #ffffff;
        position: relative;
        margin-left: 32px;

        span {
            position: relative;
        }

        svg {
            position: absolute;
            top: -40px;
            left: -50px;
        }
    }

    .faq-desc {
        font-size: 24px;
        color: #606277;
        margin-top: 20px;
        margin-bottom: 64px;
        margin-left: 32px;
        max-width: 550px;
    }

    .faq-item {
        padding: 86px 32px 86px 32px;
        border-radius: 32px;
        overflow: hidden;
        position: relative;
        transition: 0.4s;

        &.active {

            &:after,
            &:before {
                opacity: 1;
            }

            .item-header {
                svg {
                    transform: rotate(45deg);
                }
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            right: 2px;
            bottom: 2px;
            border-radius: 32px;
            background: #020734;
            opacity: 0;
            transition: 0.4s;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: radial-gradient(80.69% 208.78% at 108.28% 112.58%, #eabfff 0%, rgba(135, 38, 183, 0) 100%),
                radial-gradient(80.38% 222.5% at -13.75% -12.36%, #98f9ff 0%, rgba(255, 255, 255, 0) 100%);
            opacity: 0;
            transition: 0.4s;
        }

        .item-body {
            position: relative;
            z-index: 1;
            display: none;

            p {
                font-size: 24px;
                color: #ffffff;
                margin: 32px 80px;

                a {
                    font-weight: 700;
                    color: #fff;
                }
            }
        }

        .item-header {
            display: flex;
            justify-content: space-between;
            position: relative;
            z-index: 1;
            cursor: pointer;

            >span {
                display: flex;
                margin-right: 32px;
                flex: 1;
                font-weight: 700;
                font-size: 28px;
                color: #ffffff;

                >span {
                    margin-right: 55px;
                    display: block;
                }
            }

            svg {
                transition: 0.4s;
            }
        }
    }

    @media only screen and (min-width: 1200px) {
        &:not(.home-faq-container) {
            opacity: 0;
            transform: translate(0, 50px);
        }

        .faq-item {
            opacity: 0;
            transform: translate(0, 50px);
        }
    }

    @media only screen and (max-width: 767px) {
        .faq-title {
            font-size: 50px;
            margin-left: 0;

            svg {
                width: 50%;
                height: auto;
                left: -10px;
                top: -17px;
            }
        }

        .faq-desc {
            margin-left: 0;
            font-size: 18px;
            margin-bottom: 50px;
        }

        padding: 180px 24px 0;

        .faq-item {
            .item-header {
                >span {
                    >span {
                        margin-right: 24px;
                    }

                    font-size: 20px;
                }

                svg {
                    height: 24px;
                }
            }

            padding: 26px 32px;

            .item-body p {
                font-size: 16px;
                margin: 32px 0 32px 40px;
                line-height: 1.2;
            }

            &.active {
                padding: 56px 32px;
                margin: 16px 0;
            }
        }
    }
}

/* Google Fonts - Poppins */
.wrapper2 {
    position: fixed;
    bottom: 0%;
    left: 0%;
    right: 0%;
    z-index: 999;
    padding: 15px 5px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: #1f1d1d;
    //background: red;
    border-radius: 8px;
    //border: 2px solid gray;
    //padding: 15px 25px 22px;
    transition: right 0.3s ease;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

}

.wrapper2 .data{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.data-detail{
    display: flex;
    justify-content: center;
    align-items: center;
    //padding-left: 1rem;
    flex: 2;
}
.data-detail i {
    margin-right: 1rem;
}
.data-close i {
    margin-right: 1rem;
   cursor: pointer;
}

.wrapper2 .data p {
    font-size: 0.9rem;
    //color: #6e6c6c;
    color: white;
}
.wrapper2 .data p a {
    text-decoration: underline;
    margin-left: 3px;
}

@media only screen and (max-width: 500px) {
    .wrapper2 {
        left: 0%;
        bottom: 0%;
        max-width: 350px;
    }

    .header h2 {
        font-size: 20px;
    }

    
}

@media only screen and (max-width: 640px) {
    .wrapper2 .data p {
        font-size: 12px;
    }
}

.show {
    display: none;
}

.wrapper .header {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.header i {
    color: #4070f4;
    font-size: 32px;
}

.header h2 {
    color: #4070f4;
    font-weight: 500;
}

.wrapper .data {
    margin-top: 16px;
}

.data p a {
    color: #4070f4;
    text-decoration: none;
}

.data p a:hover {
    text-decoration: underline;
}

.wrapper .buttons {
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttons .button {
    border: none;
    color: #fff;
    padding: 8px 0;
    border-radius: 4px;
    background: #0642ff;
    cursor: pointer;
    width: calc(100% / 2 - 10px);
    transition: all 0.2s ease;
}

.buttons #acceptBtn:hover {
    background-color: #4373e1;
}

#declineBtn {
    border: 2px solid #4070f4;
    background-color: #fff;
    color: #4070f4;
}

#declineBtn:hover {
    background-color: #4070f4;
    color: #fff;
}
